//************************
//    VARIABLES
//************************
$gl-gridName:   grid !default;
$gl-colName:   col !default;
$gl-gutter:    $contentPadding !default; // Total
$gl-col-bottom: $gl-gutter !default;

$gl-xs:        "screen and (max-width: 35.5em)" !default; // up to 568px
$gl-sm:        "screen and (max-width: 48em)" !default; // max 768px
$gl-md:        "screen and (max-width: 64em)" !default; // max 1024px
$gl-lg:        "screen and (max-width: 80em)" !default; // max 1280px

$glp_col-1: (100%/12);
$glp_col-2: ($glp_col-1*2);
$glp_col-3: ($glp_col-1*3);
$glp_col-4: ($glp_col-1*4);
$glp_col-5: ($glp_col-1*5);
$glp_col-6: ($glp_col-1*6);
$glp_col-7: ($glp_col-1*7);
$glp_col-8: ($glp_col-1*8);
$glp_col-9: ($glp_col-1*9);
$glp_col-10: ($glp_col-1*10);
$glp_col-11: ($glp_col-1*11);
$glp_col-12: 100%;

$glp_col-5-12: (100%/5);
$glp_col-7-12: (100%/7);
$glp_col-8-12: (100%/8);
$glp_col-9-12: (100%/9);
$glp_col-10-12: (100%/10);
$glp_col-11-12: (100%/11);

// Function to generate the grid by number
@mixin makeGridByNumber($grid)
{
  [class*="#{$grid}-1"] > .#{$gl-colName}, [class*="#{$grid}-1"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-12; max-width: $glp_col-12; }
  [class*="#{$grid}-2"] > .#{$gl-colName}, [class*="#{$grid}-2"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-6; max-width: $glp_col-6; }
  [class*="#{$grid}-3"] > .#{$gl-colName}, [class*="#{$grid}-3"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-4; max-width: $glp_col-4; }
  [class*="#{$grid}-4"] > .#{$gl-colName}, [class*="#{$grid}-4"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-3; max-width: $glp_col-3; }
  [class*="#{$grid}-5"] > .#{$gl-colName}, [class*="#{$grid}-5"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-5-12; max-width: $glp_col-5-12; }
  [class*="#{$grid}-6"] > .#{$gl-colName}, [class*="#{$grid}-6"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-2; max-width: $glp_col-2; }
  [class*="#{$grid}-7"] > .#{$gl-colName}, [class*="#{$grid}-7"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-7-12; max-width: $glp_col-7-12; }
  [class*="#{$grid}-8"] > .#{$gl-colName}, [class*="#{$grid}-8"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-8-12; max-width: $glp_col-8-12; }
  [class*="#{$grid}-9"] > .#{$gl-colName}, [class*="#{$grid}-9"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-9-12; max-width: $glp_col-9-12; }
  [class*="#{$grid}-10"] > .#{$gl-colName}, [class*="#{$grid}-10"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-10-12; max-width: $glp_col-10-12; }
  [class*="#{$grid}-11"] > .#{$gl-colName}, [class*="#{$grid}-11"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-11-12; max-width: $glp_col-11-12; }
  [class*="#{$grid}-12"] > .#{$gl-colName}, [class*="#{$grid}-12"] > [class*='#{$gl-colName}-']{ flex-basis: $glp_col-1; max-width: $glp_col-1; }
}

// Function to generate the grid by columns
@mixin makeCol($col)
{
    [class*='#{$gl-gridName}'] {
        > [class*="#{$col}-1"]{ flex-basis: $glp_col-1;max-width: $glp_col-1; }
        > [class*="#{$col}-2"]{ flex-basis: $glp_col-2;max-width: $glp_col-2; }
        > [class*="#{$col}-3"]{ flex-basis: $glp_col-3;max-width: $glp_col-3; }
        > [class*="#{$col}-4"]{ flex-basis: $glp_col-4;max-width: $glp_col-4;}
        > [class*="#{$col}-5"]{ flex-basis: $glp_col-5;max-width: $glp_col-5;}
        > [class*="#{$col}-6"]{ flex-basis: $glp_col-6;max-width: $glp_col-6;}
        > [class*="#{$col}-7"]{ flex-basis: $glp_col-7;max-width: $glp_col-7;}
        > [class*="#{$col}-8"]{ flex-basis: $glp_col-8;max-width: $glp_col-8;}
        > [class*="#{$col}-9"]{ flex-basis: $glp_col-9;max-width: $glp_col-9;}
        > [class*="#{$col}-10"]{ flex-basis: $glp_col-10;max-width: $glp_col-10;}
        > [class*="#{$col}-11"]{ flex-basis: $glp_col-11;max-width: $glp_col-11;}
        > [class*="#{$col}-12"]{ flex-basis: $glp_col-12;max-width: $glp_col-12;}
    }
}

// Function to generate the offset
@mixin makeOff($off)
{
    [class*="#{$gl-gridName}"]{
        > [data-push-left*="#{$off}-0"]{ margin-left: 0; }
        > [data-push-left*="#{$off}-1"]{ margin-left: $glp_col-1; }
        > [data-push-left*="#{$off}-2"]{ margin-left: $glp_col-2; }
        > [data-push-left*="#{$off}-3"]{ margin-left: $glp_col-3; }
        > [data-push-left*="#{$off}-4"]{ margin-left: $glp_col-4; }
        > [data-push-left*="#{$off}-5"]{ margin-left: $glp_col-5; }
        > [data-push-left*="#{$off}-6"]{ margin-left: $glp_col-6; }
        > [data-push-left*="#{$off}-7"]{ margin-left: $glp_col-7; }
        > [data-push-left*="#{$off}-8"]{ margin-left: $glp_col-8; }
        > [data-push-left*="#{$off}-9"]{ margin-left: $glp_col-9; }
        > [data-push-left*="#{$off}-10"]{ margin-left: $glp_col-10; }
        > [data-push-left*="#{$off}-11"]{ margin-left: $glp_col-11; }

        > [data-push-right*="#{$off}-0"]{ margin-right: 0; }
        > [data-push-right*="#{$off}-1"]{ margin-right: $glp_col-1; }
        > [data-push-right*="#{$off}-2"]{ margin-right: $glp_col-2; }
        > [data-push-right*="#{$off}-3"]{ margin-right: $glp_col-3; }
        > [data-push-right*="#{$off}-4"]{ margin-right: $glp_col-4; }
        > [data-push-right*="#{$off}-5"]{ margin-right: $glp_col-5; }
        > [data-push-right*="#{$off}-6"]{ margin-right: $glp_col-6; }
        > [data-push-right*="#{$off}-7"]{ margin-right: $glp_col-7; }
        > [data-push-right*="#{$off}-8"]{ margin-right: $glp_col-8; }
        > [data-push-right*="#{$off}-9"]{ margin-right: $glp_col-9; }
        > [data-push-right*="#{$off}-10"]{ margin-right: $glp_col-10; }
        > [data-push-right*="#{$off}-11"]{ margin-right: $glp_col-11; }
    }
}
