.btn{
  text-align: center;
  font-weight: bold;
   color: $btnblue;
   border: 1px solid $btnblue;
   background-color: #fff;
   background: linear-gradient(to bottom, #ffffff 0%,#eaeaea 100%);
   border-radius: 5px;
   padding: 10px 0;
   box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
  -moz-box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
  -webkit-box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
    transition: all 0.2s ease;
  &:hover{
    background: rgba(27,121,188,1);
    color: #fff;
    @include mq-max(md) {
      background: #fff;
      background: linear-gradient(to bottom, #ffffff 0%,#eaeaea 100%);
      color: $btnblue;
    }
  }
}

//カート(購入する)ボタン
.cart,.buy_this_with {
  label{
    display: inline;
    padding: 3px 25px 5px;
    border-radius: 5px;
    color: #fff;
    //background: linear-gradient(to bottom, #4f9525 0%,#468421 100%);
    background: linear-gradient(to bottom, #2da930 0%,#28952a 100%);
    transition: all 0.2s ease;
    cursor: pointer;
      @include mq-max(md){
        padding: 0 10px 4px;
      }

      &.label_md_wide{
        @include mq-max(md){
          display: inline-block;
          width: 100%;
          padding: 15px 25px;
          text-align: center;
          margin-left: 0;
        }
      }

    &:hover{
      opacity: 0.85;
      // background: linear-gradient(to bottom, darken(#4f9525,5%) 0%,darken(#468421,5%) 100%);
    }
    &::before{
      font-family: fontawesome;
      content: "\f07a";
      position: relative;
      margin-right: 1em;
      vertical-align: middle;
      background: transparent;
      width: inherit;
      height: inherit;

    }
    &::after{
      content: attr(data-cart);
      position: relative;
      vertical-align: middle;
      border: none;
      top: 0;
      // right: 0;
    }

    input{
      width: 0;
      visibility: hidden;
    }
  }

  input#submit{

    @include mq-max(md) {
    // padding: 2vh 0;
    // background-image: none;
    // border-radius: 0;
    }

  }
}
