// サブセット版 notosansの使用
 $path : "//mt-agi-c.japan-onlinestores.com/resources/c_media/themes/theme_0";
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 100;
  src: url( $path + '/font/notosans/NotoSansJP-Thin.woff2') format('woff2');
  src: url( $path + '/font/notosans/NotoSansJP-Thin.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Thin.otf') format('opentype'), url($path + '/font/notosans/NotoSansJP-Thin.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 200;
  src: url( $path + '/font/notosans/NotoSansJP-Light.woff2') format('woff2');
  src: url( $path + '/font/notosans/NotoSansJP-Light.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Light.otf') format('opentype'), url( $path + '/font/notosans/NotoSansJP-Light.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 300;
  src: url( $path + '/font/notosans/NotoSansJP-DemiLight.woff2') format('woff2');
  src: url( $path + '/font/notosans/NotoSansJP-DemiLight.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-DemiLight.otf') format('opentype'), url('../font/notosans/NotoSansJP-DemiLight.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 400;
  src: url($path + '/font/notosans/NotoSansJP-Regular.woff2') format('woff2');
  src: url($path + '/font/notosans/NotoSansJP-Regular.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Regular.otf') format('opentype'), url( $path + '/font/notosans/NotoSansJP-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 500;
  src: url($path + '/font/notosans/NotoSansJP-Medium.woff2') format('woff2');
  src: url($path + '/font/notosans/NotoSansJP-Medium.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Medium.otf') format('opentype'), url($path + '/font/notosans/NotoSansJP-Medium.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 700;
  src: url($path + '/font/notosans/NotoSansJP-Bold.woff2') format('woff2');
  src: url($path + '/font/notosans/NotoSansJP-Bold.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Bold.otf') format('opentype'), url( $path + '/font/notosans/NotoSansJP-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Noto Sans Japanese';
  font-style: normal;
  font-weight: 900;
  src: url($path + '/font/notosans/NotoSansJP-Black.woff2') format('woff2');
  src: url($path + '/font/notosans/NotoSansJP-Black.ttf') format('truetype'), url($path + '/font/notosans/NotoSansJP-Black.otf') format('opentype'), url( $path +'/font/notosans/NotoSansJP-Black.woff') format('woff');
}
