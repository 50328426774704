#header {
  background-color: #efefef;
  // top: -100px;
  // position: absolute;
  // width: 100%;
  // margin: 100px auto 0;
  // line-height: 1;
  z-index: 999;
  @include mq-max(md) {
    background-color: transparent;
    width: 100%;
    padding: 0;
    top: 0;
//    position: absolute;
    margin-top: 0;
  }
  @include clearfix;


  .inner {
    // width: 980px;
    position: relative;
    margin: 0 auto;
    @include mq-max(md) {
      width: 100%;
      padding: 0;
    }
    /* Toggle Button */
    #nav-toggle {
      display: none;
      position: absolute;
      right: 10px;
      top: 10px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 101;
      @include mq-max(md) {
        padding: 14px 10px;
        position: fixed;
        background: linear-gradient(to bottom, #34a2ee 0%,#1b79bc 100%);
        display: block;
      }

      div {
        position: relative;
      }

      span {
        display: block;
        position: absolute;
        height: 2px;
        background: #fff;
        width: 100%;
        -webkit-transition: 0.35s ease-in-out;
        -moz-transition: 0.35s ease-in-out;
        transition: 0.35s ease-in-out;
        &:nth-child(1) {
          top: 0px;
        }

        &:nth-child(2) {
          top: 6px;
        }

        &:nth-child(3) {
          top: 12px;
        }
      }
    }
  }

  #mobile-head {
    height: 95px;
    //padding: 10px 0;
    padding: 10px 0;
    @include mq-max(md) {
      height: auto;
      padding: 10px 10px 0 10px;
    }
    h1{
      display: inline-block;
        a{
          display: inline-block;
          transition: all 0.2s ease;
          &:hover{
            opacity: 0.8;
          }
        }
        img{
          width: 137px;
          height: auto;
          margin: 12px 0px 12px 22px;
          @include mq-max(md){
            margin: 12px 0px 12px 12px;
          }
        }
    }
  }
  .headRight{
    position: absolute;
    top: 10px;
    right: 10px;
    @include clearfix;
    @include mq-max(md) {
      position: static;
      margin: 0;
      }
      ul{
        text-align: right;
        margin-bottom: 10px;
        @include mq-max(md) {
          text-align: center;
          margin: 0;
        }

        li{
          display: inline-block;
          @include mq-max(md) {
            display: block;
          }
          a{
            display: block;
            @include mq-max(md) {
              padding: 2vh 0;
            }
          }
        }
      }

    .selectList{
      @include mq-max(md) {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        margin-bottom: 15px;
      }
      li{
        @include mq-max(md) {
          display: inline-block;
          padding: 5px 0;
        }

      }
    }

    .menuList{
      @include mq-max(md) {
        border-bottom: 1px solid #fff;
        padding-bottom: 2vh;
      }

      li{
        margin-left: 20px;
        @include mq-max(md) {
          margin-left: 0;
        }

        &:first-child{
          @include mq-max(md) {
            display: none;
          }
        }
        i{
          margin-right: 3px;
        }
        a{
          &:before{
            font-family: Fontawesome;
            margin-right: 5px;
          }
        }
      }
      // マイアカウントボタン
      .myaccount{
        @include mq-max(md) {
          // display: block;
        }
        a{
          color: #4f9525;
          position: relative;
          display: inline-block;
          transition: .2s;
          &:after {
          position: absolute;
          bottom: 0;
          left: 0;
          content: '';
          width: 0;
          height: 1px;
          background-color: #4f9525;
          transition: .2s;
          }
          &:hover:after {
            width: 100%;
          }

          &:before{
            content: "\f090";
            @include mq-max(md) {
              content: "";
            }
          }
          @include mq-max(md) {
            color: #fff;
          }

        }
      }
      // カートボタン
      .cart{
        a{
          padding: 3px 15px;
          border-radius: 5px;
          color: #fff;
          background: linear-gradient(to bottom, #4f9525 0%,#468421 100%);
          transition: all 0.2s ease;
          &:hover{
            opacity: 0.85;
            // background: linear-gradient(to bottom, darken(#4f9525,5%) 0%,darken(#468421,5%) 100%);
          }
          @include mq-max(md) {
          padding: 2vh 0;
          background: none;
          border-radius: 0;
          }
          &:before{
            content: "\f07a";
            @include mq-max(md) {
              content: "";
            }
          }
        }
      }
    }
  }
    &.open {
      #nav-toggle{
        @include mq-max(md) {
          padding: 0;
          background: none;
        }
        span{
          background: #fff;
          &:nth-child(1) {
            top: 11px;
            -webkit-transform: rotate(315deg);
            -moz-transform: rotate(315deg);
            transform: rotate(315deg);
          }
          &:nth-child(2) {
            width: 0;
            left: 50%;
          }
          &:nth-child(3) {
            top: 11px;
            -webkit-transform: rotate(-315deg);
            -moz-transform: rotate(-315deg);
            transform: rotate(-315deg);
          }
        }
        &:after{
          color: #fff;
          content: "CLOSE";
          display: block;
          font-size: .8em;
          position: absolute;
          bottom: -10px;
        }
      }
      /* #globalnavi スライドアニメーション */
      #menu {
        /* #globalnavi top + #mobile-head height */
        // -moz-transform: translateY(556px);
        // -webkit-transform: translateY(556px);
        transform: translateY(100%);
        }
      }


  #menu {
    background: linear-gradient(to bottom, #34a2ee 0%,#1b79bc 100%);
    margin-top: 4px;
    @include clearfix;
    a{
      @include mq-max(md) {
        font-size: 1.1em;
        font-weight: lighter;
      }
    }
    @include mq-max(md) {
      padding-top: 60px;
      position: fixed;
      top: -100%;
      left: 0;
      margin-top: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      background: rgba(0, 0, 0, 0.8);
      width: 100%;
      height: 100%;
      -webkit-transition: 0.5s ease-in-out;
      -moz-transition: 0.5s ease-in-out;
      transition: 0.5s ease-in-out;
          z-index: 100;
    }

    #globalnavi {
      // height: 100%;
      @include clearfix;
      font-size: 0;
      white-space: nowrap;
      text-align: right;
      display: table;
      float: right;
      @include mq-max(md) {
        padding-top: 2vh;
        background: none;
        font-size: 14px;
        float: none;
        margin: 0 auto;
      }

      >ul{
        display: table-cell;
        @include mq-max(md){
          display: block;
        }
        >li {
          font-size: 16px;
          display: inline-block;
          border-left: 1px solid rgba( 255, 255, 255, 0.5 );
          position: relative;
          @include mq-max(md) {
            display: block;
            position: static;
            border-left: 0;
          }
          a {
            padding: 5px 15px;
            height: 100%;
            display: block;
            color: #fff;
            &:hover {
              background: linear-gradient(to bottom, #4469a8 0%,#134090 100%);
              @include mq-max(md) {
                background: none;
              }
            }
            @include mq-max(md) {
              border: none;
              text-align: center;
              width: 100%;
              display: block;
              color: #fff;
              padding: 2vh 0;
            }
          }
          >ul {
            display: none;
          }
          &:hover{
            >ul{
              background: linear-gradient(to bottom, #34a2ee 0%,#1b79bc 100%);
              display: block;
              position: absolute;
              top: 100%;
              z-index: 100;
              @include mq-max(md){
                display: none;
              }
              >li{
                &:hover{
                  background: linear-gradient(to bottom, #4469a8 0%,#134090 100%);
                }
              }
            }
          }
        }
      }
    }
  }
}
