form {
  .search {
    position: relative;
    background-color: #fff;
    border: 1px solid #dcdcdc;
    border-radius: 6px;

    dt {
      margin-right: 25px;
      padding: 5px 0 5px 5px;

      input {
        width: 100%;
        height: 24px;
        line-height: 24px;
        background: none;
        border: none;
        &[type="text"]:focus {
          outline: 0;
        }

        &::-webkit-input-placeholder {
          color: #b2b2b2;
        }

        &::-moz-placeholder {
          color: #b2b2b2;
          opacity: 1;
        }

        &:-ms-input-placeholder {
          color: #b2b2b2;
        }
      }
    }

    dd {
      position: absolute;
      top: 0;
      right: 0;

      button {
        display: block;
        padding: 5px;
        background: none;
        border: none;
        i {
          color: #666666;
        }
      }
    }
  }
}
