// カタログエリアここから
.catalogArea{
  clear: both;
  position: relative;
  background-color: #adadad;
  &.grid-noGutter {
  //margin-top: 50px;

  }
  @include mq-max(md) {
    padding: 30px 0;
    order: 4;//Warning : 商品詳細ページが空の時にズレるのでとらないこと。
  }

  >div:first-child{
    text-align: center;
  }
  h3{
    font-size: 30px;
    font-weight: lighter;
    color: #fff;
    margin-top: 40px;
    margin-bottom: 30px;
    @include mq-max(md) {
      margin: 0;
    }

    @include mq-max(sm) {
      font-size: 22px;
      width: 80%;
      margin: 0 auto;
      text-align: left;
    }
  }
  a[download]{
    display: inline-block;
    min-width: 320px;
    @include mq-max(md) {
      min-width: auto;
      width: 80%;
      margin-top: 30px;
    }

  }
  img{
    text-align: left;
    vertical-align: bottom;
    @include mq-max(sm) {
      display: none;
    }

  }
}
// カタログエリアここまで

footer{
  background-color: #e5e5e5;

    @include mq-max(md){
        order: 5; //Warning : 商品一覧が空の際にズレるのでとらないこと。
    }

  // 検索エリア
  form{
    text-align: center;
    padding: 30px 0;
    border-bottom: 1px solid #fff;
    .search{
      border-radius: 4px;
      display: inline-block;
      text-align: center;
      width: 500px;
      box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
      -moz-box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
      -webkit-box-shadow:0px 0px 6px 2px rgba(0,0,0,0.14);
      transition: all 0.2s ease;
      &:hover{
        box-shadow:0px 0px 7px 3px rgba(0,0,0,0.2);
        -moz-box-shadow:0px 0px 7px 3px rgba(0,0,0,0.2);
        -webkit-box-shadow:0px 0px 7px 3px rgba(0,0,0,0.2);
      }
      @include mq-max(sm) {
        width: 80%;

      }

      dt{
        padding: 8px 0 8px 8px;

      }
      dd{

        button{
          padding: 8px;
          i{
            right: 15px;
          }
        }
      }
    }
  }


  >div{
    margin: 20px 20px 0 20px;
    @include clearfix;
    @include mq-max(sm) {
      margin: 0 auto;
      width: 95%;
    }

    .footerlogo, .footernavi{
      margin-top: 40px;
      @include mq-max(sm) {
        margin-top: 0;
      }
    }

    .footerlogo{
      float: left;
      margin-left: 30px;
      margin-right: 30px;
      @include clearfix;
      @include mq-max(sm) {
        float: none;
        margin: 40px auto;
        text-align: center;
      }
      a{
        display: inline-block;
        transition: all 0.2s ease;

        &:hover{
          opacity: 0.8;
        }
        img{
          width: 137px;
          vertical-align: top;
          @include mq-max(sm) {
            width: 200px;
          }
        }
      }
    }

    .footernavi{
      @include clearfix;
      float: left;
      @include mq-max(sm) {
        float: none;
        display: block;
      }

      // ul + ul{
      //   @include mq-max(sm) {
      //     margin-left: 40px;
      //   }
      // }

      ul{
        float: left;
        margin-left: 40px;
        @include mq-max(sm) {
          margin-left: 20px;
        }

        li{
          &:first-child{
            margin-bottom: 20px;
            font-weight: bold;
          }
          &:not(:first-child){
            margin-bottom: 10px;
          }
        }
        a{
          color: $textColor;
          &:hover{
            text-decoration: underline;
                      color: #2B445D;
          }
        }
      }
    }

    .companyinfo{
      float: right;
      overflow: hidden;
      // @include clearfix;
      @include mq-max(md) {
        // float: none;
        margin-top: 20px;
      }

      >div{
        margin: 0 2px;
        float: left;
        padding: 10px;
        background-color: #fff;
        width: 220px;
        height: 240px;
        @include mq-max(sm) {
          padding-bottom: 300px;
          margin: 0 1px -290px;
          width: 49%;
          height: auto;
        }

        img{
          display: block;
          margin: 0 auto 15px;
          @include mq-max(sm) {
            width: 100%;
          }

        }
        p{
          font-size: .8em;
          line-height: 1.4;
          margin: 0;
          @include mq-max(sm) {
            font-size: 10px;
          }

        }
      }
    }
  }
  // コピーライト
  small{
    padding: 30px 0 20px;
    display: block;
    text-align: center;
  }
}
