//==================
//プレイスホルダー
//==================
%itemList_inner {
  position: relative;
  // top: 1px;
  vertical-align: middle;
  text-align: center;
  display: table;
  float: left;
  width: 33.3333%;

  height: 270px;
  overflow: hidden;
  transition: all 0.2s ease;
  border-bottom: 1px solid $borderColor;
  // &.listLine{
  //   @include mq-max(sm) {
  //     width: 50%;
  //   }
  //   @include mq-max(md) {
  //     width: 33.3333%;
  //   }
  //   @include mq-max(lg) {
  //     width: 25%;
  //   }
  //   @include mq-max(xl) {
  //     width: 20%;
  //   }
  // }
  @include mq-max(sm) {
    width: 50%;
  }
  &:hover {
    background-color: #f4f4f4;
  }
  img {
    max-width: 200px;
    //max-height: 180px;
    height: auto;
    vertical-align: middle;
    @include mq-max(sm) {
      max-width: 150px;
      height: auto;
    }
  }

  .imageArea{
    display: table-cell;
    // margin: 20px auto 10px;
    max-width: 200px;
    height: 200px;
    vertical-align: middle;
  }
  span {
    display: table-row;
    color: $textColor;
    &.itemName{
      font-size: 1.1em;
      @include mq-max(md){
        font-size: 1em;
      }
    }
  }
}

//==================
//itemList
//==================

.itemList {
  .top &{
    @include mq-max(md) {
      display: none;
  }
  }

  // width: auto;
  > div {
    width: 100%;
    position: relative;
    top: 1px;
    // display: inline-block;
    @include clearfix;

    > a {
          @extend %itemList_inner;
      }
    }

    &.listLine{
      >div{
        >a{
          // @include mq-max(xl) {
            width: 20%;
          // }
          @include mq-max(lg) {
            width: 25%;
          }
          @include mq-max(md) {
            width: 33.3333%;
          }
          @include mq-max(sm) {
            width: 50%;
          }

          .imageArea{
            height: 150px;
          }

        }
      }
    }
  }


//==================
//カテゴリー（category.php）調整用scss
//==================
.sub_category_list {
  div {
    @extend %itemList_inner;
  }
}
