/*
テーブル
*/

$border:1px solid #cccccc;


.tblStyle {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
//  line-height: $lineHeight02;
  background: #fff;
  border-bottom: $border;
  + .tblStyle {
    margin-top: 30px;
  }
  th {
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    font-weight: bold;
    @include mq-max(sm){
      text-align: left;
      border-top: $border;
      width: 100%;
    }
    + th {
      border-left: $border;
    }
  }
  th, td {
    border-top: $border;
    padding: 17px;
    @include mq-max(sm){
      display: block;
      padding: 8px;
    }
  }
  td {
    @include mq-max(sm){
      border-top: none;
      padding-top: 0;
    }
    + td {
      border-left: $border;
    }
  }
  thead tr th {
    border-bottom: $border;
    padding: 20px 0;
  }

  thead tr:first-child,
  tbody tr:first-child {
    th, td {
      border-top: none;
    }
  }
  thead + tbody tr:first-child {
    th, td {
      border-top: $border;
    }
  }

  // テーブル構成が複雑なテーブル(rowspanやcolspanがあるもの)
  &.narrow {
    font-size: 88%;
    @include mq-max(md){
      font-size: 80%;
    }
    th, td {
      padding: 6px;
      @include mq-max(sm){
        display: table-cell;
      }
    }
    td {
      border-left: $border;
      @include mq-max(sm){
        border-top: $border;
      }
    }
  }

  // ボーダーだけのシンプルなテーブル
  &.borderOnly {
    border: none;
    th {
      background: transparent;
      font-weight: normal;
      text-align: left;
    }
    th, td {
      border-top: none;
      border-bottom: $border;
    }
    &.likeList {
      tr {
        @include mq-max(sm){
          td:first-of-type {
            padding-top: 15px;
          }
          td:last-child {
            padding-bottom: 20px;
          }
        }
      }
      td {
        @include mq-max(sm){
          border-bottom: none !important;
          padding: 5px 0 5px 20px;
        }
      }
    }
  }
  // 複雑なテーブル
  &.complicated {
    // font-size: 90%;
    th,td {
      @include mq-max(sm){
        display: table-cell;
      }
    }
    td {
      @include mq-max(sm){
        border-top: $border;
      }
    }
    thead tr:first-child,
    tbody tr:first-child {
      th, td {
        border-top: none;
      }
    }
  }
}
@include mq-max(sm){
  .scrollable-info:before {
    content: "";
    width: 100%;
    display: block;
    padding: 0;
    background-color: $keyColor;
    color: #fff;
    text-align: center;
  }
  .wrap-scrolltable {
    width: 100%;
    overflow-x: scroll;
    margin-bottom: 20px;
    position: relative;
    border: $bdr_default $bdr_gray;
  }
}


// 改行なし
.nobreak {
  white-space: nowrap;
}
// 余白なしセル
.tblStyle .nopadCell {
  padding: 0;
}
// テーブルヘッダーの画像
.tblStyle .thImg {
  width: 222px;
  @include mq-max(sm){
  }
}
