/*===============
レスポンシブ用　上書きスタイル
================*/

@include mq-max(md) {
  #contents {
    width: 100% !important;
    overflow-x: hidden; //横ブレ防止用
    //    フッターからのリンク先固定ページ
    .fullFrame {
      width: 100% !important;
      margin: 0 !important;
      ;
      padding: 0 15px;
      .categoryFrame {
        width: 100%;
      }
      //      ログイン画面
      .loginFrame {
        background: none;
        width: 100% !important;
        &>div {
          float: none;
          width: 100%;
        }
        &+img {
          display: none;
        }
        //ログインフォーム
        .login {
          position: relative;
          float: none;
          width: 100%;
        }
        .btn_edit {
          position: relative;
          left: 0;
          right: 0;
          width: 100%;
          text-align: right;
        }
        .btn_login {
          bottom: 0;
        }
      }
    }
    .subFrame {
      //サイドメニュー
      .cart_contener {
        display: none;
      }
    }
    //アカウント作成&作成完了
    #mainSide.detailFrame {//.detailFrameのみだと製品一覧ページと干渉するので注意
      width: 100%!important;
      float: none!important;
      padding: 0 15px;
    }

//    ログイン画面
    .fullFrame{
      .loginFrame  {
        margin-top: 0;
        padding-top: 0;
        #LoginForm {
          table {
            margin: 0 auto;
            th,td {
              display: block;
            }
          }
          .btn_login {
            position: relative;
          }
        }
        p.btn_edit,p.btn_login {
          position: relative;
          width: 100%;
          text-align: center;
        }

      }
    }



    //    contact
    .categoryFrame {
      div.form {
        fieldset {
          padding: 10px;
          #ddbody {
            textarea {
              width: 16em;
            }
          }
        }
      }
      >p{//お問合せフォームで使用
        width: 100%;
        padding: 0 15px;
      }
      >form{//お問わせフォーム
        width: 100%;
        padding: 0 15px;

        //エラーメッセージ
        .textfieldRequiredState .textfieldRequiredMsg, .textfieldInvalidFormatState .textfieldInvalidFormatMsg, .textfieldMinValueState .textfieldMinValueMsg, .textfieldMaxValueState .textfieldMaxValueMsg, .textfieldMinCharsState .textfieldMinCharsMsg, .textfieldMaxCharsState .textfieldMaxCharsMsg, .textfieldErrorState .error {
          display: block;
        }
      }
    }
    //  検索-Advanced search(詳細検索フォーム)
    .form {
      fieldset {
        padding: 0;
        .form-list {
          dd {
            width: 100%;
            input {}
            small {
              display: block;
            }
          }
        }
      }
    }
    //  検索-Advanced search(検索結果の表示画面)
    #search_results {
      .itemlist {
        .item {
          padding: 20px;
          .image {
            float: none;
            width: 100%;
          }
          .data{
            float: none;
            width: 100%;
          }
          .datalist {
            li {
              float: none;
              width: 100%;
            }
          }
        }
      }
    }


//    パスワード再発行画面
    #PasswordForm {
      .button {
        text-align: center;
        a {
          float: none;
        }
      }
    }
  }

//購入ステップ
#mainNav{
  font-size: 50%;
  margin-left: 0;
  li a em{
    margin: 3px 0 0 3px!important;
  }
  li a span{
    margin-left: 3px!important;
  }
}

// /checkout/shipping テキストエリア
#payment_option{
  textarea{
    width: 85%;
  }
}

.form{
  textarea{
    width: 100%!important;
  }
}

}
