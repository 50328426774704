// 下層全体に適用するCSS
body{
  color: $textColor;
}

.lowerTitle{
  height: 180px;
  padding-left: 20px;
  padding: 40px 0 40px 20px;
  //background: #f7f7f7 url("../image/bg_lowertitle_EasyMax102.png");
  border-bottom: 1px solid #bfbfbf;
  &.bg_apparatus{
    background: #f7f7f7 url( $path + "/images/bg_lowertitle_EasyMax102.png");
    background-repeat: no-repeat;
    background-position: top -105px right -75px;
    background-size: auto;
  }
  &.bg_glassparts{
    background: #f7f7f7 url( $path + "/images/bg_lowertitle_glassparts.png");
    background-repeat: no-repeat;
    background-position: top -70px right -15px;
    background-size: auto;
  }
  &.bg_others{
    background: #f7f7f7 url( $path + "/images/bg_lowertitle_others.png");
    background-repeat: no-repeat;
    background-position: top -10px right -60px;
    background-size: auto;
  }
  @include mq-max(md) {
    padding: 60px 0 40px 10px;
  }
  @include mq-max(sm) {
    padding: 80px 0 20px 10px;
    height: 100%;
    background-size: 300px 300px;
    order: 1;
  }

  h2{
    @include mq-max(md) {
      margin-top: 0;
    }


  }


  ul{
    font-size: .9em;
    // margin: 20px 0;
    @include mq-max(md) {
      // margin-top: 0;
    }
    @include mq-max(sm) {
      font-size: 11px;
      // margin: 0;
    }

    li{
      display: inline;
      margin-right: 5px;
    }
  }
}

.contentsArea{
  // position: relative;
  @include clearfix;
  @include mq-max(sm) {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
}

// 製品ページ以下サイドメニューエリア
.menuFrame{
  float: left;
  width: 20%;
  @include mq-max(md) {
    width: 30%;
    border: none;
    height:auto;
  }
  @include mq-max(sm) {
    float: none;
    width: 100%;
    order: 3;
    border-top: 1px solid $borderColor;
  }


// サイドナビ
  .navigation{
    >ul{
      &:not(:first-child){
        border-top: 1px solid $borderColor;
      }
      &:last-of-type{
        border-bottom: 1px solid $borderColor;
      }
      >li{
        >a{//--親カテゴリ
          padding: 20px;
          font-size: 1.1em;
          &:hover{
            background-color: #f4f4f4;
          }

          &.active{
            background-color: #f4f4f4;
          }
        }
        //--子カテゴリ
        >ul{
          padding: 20px 0;
        }
        li{
          margin-left: 2em;
          // text-indent: 2em;
          &:not(:last-child){
            margin-bottom: 10px;
          }
          a{
            &:hover{
              text-decoration: underline;
              color: $btnblue;
              @include mq-max(sm) {
                text-decoration: none;
              }
            }
            &.active{
              font-weight: 500;
              color: $btnblue;
            }
          }
        }
        >ul{
          ul{
            padding: 10px 0 0;
            li{
              text-indent: 3em;
            }
          }
        }
      }
    }
    a{
      display: block;
    }
  }



  dt{
    a{
      font-size: 1.1em;
      padding: 20px;
      display: block;
      transition: all 0.2s ease;
      &:hover{
        background-color: #f4f4f4;
      }
    }
    &.current{
      background-color: #f4f4f4;
    }
    &:not(:first-child){
      border-top: 1px solid $borderColor;
    }
    &:last-of-type{
      border-bottom: 1px solid $borderColor;
    }
  }
  dd{
    padding: 20px 0;
    text-indent: 2em;
    li{
      &:not(:last-child){
        margin-bottom: 10px;
      }
      a{
        display: block;
        &:hover{
          text-decoration: underline;
          color: #0c6fb4;
          @include mq-max(sm) {
            text-decoration: none;
          }
        }
        //現在のページ
        &.current{
          //font-weight: 500;
          color: #0c6fb4;
        }
      }
    }
  }
}


// 製品ページ以下メインエリア
.detailFrame{
  float: right;
  width: 80%;
  min-height: 660px;
  border-left: 1px solid $borderColor;
  @include mq-max(md) {
    width: 70%;
  }
  @include mq-max(sm) {
    float: none;
    width: 100%;
    border: none;
    order: 2;
  }

  >span{
    &:first-child{
      display: block;
      padding: 10px 15px 10px 0;
      text-align: right;
      border-bottom: 1px solid #bfbfbf;
      @include mq-max(md){
        padding: 10px 0;
      }
      a{
        &:hover{
          text-decoration: underline;
          color: #1e7fc3;
        }
        @include mq-max(md){
          margin-right: 10px;
          margin-top: .5em;
          display: block;
        }
      }
      label{
        margin-right: 10px;
        margin-left: 5px;
        position: relative;
        display: inline-block;
        background: #fff;
        &::before{
          content: '';
          position: absolute;
          z-index: 0;
          top: 0;
          right: 0;
          background: #dcdcdc;
          height: 100%;
          width: 30px;
        }
        &::after{
          content: '';
          position: absolute;
          z-index: 0;
          top: 0;
          bottom: 0;
          margin: auto 0;
          right: 11px;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 6px 4px 0 4px;
          border-color: #666 transparent transparent transparent;
        }
      }
      select[name="product_sort"]{
        // margin-right: 10px;
        padding: 0 40px 0 10px;
        width: 250px;
        position: relative;
      }
      span{
        color: #1e7fc3;
        @include mq-max(md){
          margin-right: 0.25em;
        }
      }
    }
  }

// 詳細ページ画像部分
.imageFrame{
  padding: 30px 0;
  // position: relative;
  border-bottom: 1px solid $borderColor;
  @include clearfix;
  >div{
    float: left;
    @include mq-max(md) {
      float: none;
    }

    &.image{
      width: 80%;
      text-align: center;
      @include mq-max(md) {
        margin-bottom: 30px;
        width: 100%;
      }

      img{
        max-width: 400px;
        max-height: 400px;
        vertical-align: bottom;
        @include mq-max(sm) {
          width: 100%;
          height: auto;
        }

      }
    }
    &.others{
      width: 20%;
      max-height: 500px;
      @include mq-max(md) {
        display: inline-block;
        width: 70%;
        text-align: center;
        max-height: auto;
      }
      @include mq-max(sm) {
        width: 100%;
      }

      ul{
        display: inline-block;
        @include clearfix;
        &:not(:first-child){
          margin-left: 10px;
        }
        @include mq-max(md) {
          text-align: center;
        }

      }
      li{
        border: 1px solid $borderColor;
        padding: 5px;
        transition: all 0.2s ease;
        &:hover{
          border: 1px solid #2f99e3;
        }
        @include mq-max(md) {
          float: left;
          margin-bottom: 5px;
        }
        &:not(:last-of-type){
          margin-bottom: 8px;
          @include mq-max(md) {
            margin-bottom: 5px;
            margin-right: 5px;
          }
        }
      }
      img{
        width: 60px;
        height: 60px;
        vertical-align: bottom;
        @include mq-max(md) {
          width: 55px;
          height: 55px;
        }
      }
    }
  }
}

//詳細ページ画像下文言部分
  .textFrame{
    padding: 30px;
    @include mq-max(md) {
      // width: 95%; <-.phngrmjp
      padding: 30px 0;
      margin: 0 auto;
    }
      h4 {
        // font-weight: bold;
//        color: #0c6fb4;
      }
    .itemTitle{
      >span:nth-of-type(2){
        display: block;
        font-size: .9em;
        margin: 10px 0;
      }
    }

    form{
      padding: 10px 0;
      margin: 30px 0;
      border-top: 1px solid $borderColor;
      border-bottom: 1px solid $borderColor;
    }

    ul + ul{
      text-align: right;
      @include mq-max(sm) {
        text-align: left;
      }

    }

    .put_cart{
      // text-align: center;

      li{
        padding: 10px 0;
        vertical-align: middle;
        @include mq-max(sm) {
          text-align: left;
          padding: 10px 0;
        }
        &:first-child{
          // padding: 5px 0;
          @include mq-max(sm) {
            padding: 10px 0;
            // text-align: left;
          }
        }
        dl{
          display: table;
          width: 100%;
          @include clearfix;
          dt,dd{
            display: table-cell;
            @include mq-max(sm) {
              display: block;
            }
          }
          dt{
            width: 10%;
            min-width: 70px;
            @include mq-max(sm) {
              width: auto;
              min-width: auto;

            }

          }
          dd{
            // display: inline-block;
          }
          *{
            vertical-align: middle;
          }
        }
        span{
          font-size: .9em;
          @include mq-max(sm) {
            display: block;
            margin-bottom: 5px;
          }

          &.supplement{
            //font-size: .7em;  //jp server
            font-weight: 400; //mt-agi
            font-size: 12px; //mt-agi
            margin-left: 10px;
            @include mq-max(sm) {
              display: inline-block;
              margin-bottom: 0;

            }

          }
        }

        >*{
          vertical-align: middle;
        }
      }

      strong{
        font-weight: 500;
        font-size: 1.5em;
        @include mq-max(sm) {
          font-size: 1.5em;
          margin-left: 0;
        }

      }

      // button[type=submit]{
      //   border: 1px solid #fff;
      //   background: linear-gradient(to bottom, #2da930 0%,#28952a 100%);
      //   color: #fff;
      //   border-radius: 5px;
      //   padding: 5px 25px;
      //   transition: all 0.2s ease;
      //   &:hover{
      //     opacity: 0.85;
      //     // background:  rgba(255,255,255,0.5);
      //     // border: 1px solid #2da930;
      //     // background-color: #fff;
      //     // color: #2da930;
      //   }
      //   @include mq-max(sm) {
      //     padding: 15px 25px;
      //     width: 100%;
      //   }
      //
      //
      //   i{
      //     margin-right: 10px;
      //   }
        // &:before{
        //   content: "\f07a";
        //   font-family: Fontawesome;
        // }
      }
      select{
        margin-left: 20px;
        @include mq-max(sm) {
          margin-left: 0;
        }
      }
    }

    .number{
      >div{
        display: inline-block;
        border: 1px solid $borderColor;
          @include mq-max(md){
            display: block;
            width: 175px;
            margin-top: 5px;
          }
        input{
          padding: 5px 0;
          text-align: center;
          display: inline-block;
          &:first-child{
            font-weight: bold;
          }
          &:not(:last-child){
            border-right: 1px solid $borderColor;
          }
          &:not(:first-child){
            text-align: center;
            width: 30px;
          }
          @include mq-max(sm) {
            width: 100px;
            padding: 10px 0;
          }
        }
      }
    }

    .spec{
      dl{
        background-color: #f8f8f8;
        padding: 20px;
        width: 100%;
        @include clearfix;
        @include mq-max(sm) {
          padding: 10px;
        }

        dt,dd{
          display: block;
          float: left;
        }
        dt{
        width: 20%;
        clear: left;
        &:not(:last-of-type){
          margin-bottom: 5px;
        }
        @include mq-max(sm) {
          width: 30%;
        }

        }
        dd{
        width: 80%;
        @include mq-max(sm) {
          width: 70%;
        }

        }
      }
    }

    >p{
      margin: 30px 0;
      line-height: 1.8em;
    }



    .related{
      li{
        display: inline-block;
        text-align: center;
        width: 100px;
        margin-right: 20px;
        // height: 90px;


        a{
          border: 1px solid $borderColor;
          padding: 5px;
          display: inline-block;
          transition: all 0.2s ease;
          &:hover{
            border: 1px solid #2f99e3;
          }

        }

        img{
          width: 90px;
          height: 90px;
          vertical-align: bottom;
        }
        p{
          line-height: 1.3;
          margin: 0;
          font-size: .8em;
          display: inline-flex;
        }
      }
    }
    // ---./related

    .xSellProduct{
      a{
        border: 1px solid $borderColor;
        padding: 5px;
        display: inline-block;
        transition: all 0.2s ease;
        &:hover{
          border: 1px solid #2f99e3;
        }

      }

      img{
        width: 90px;
        height: 90px;
        vertical-align: bottom;
          @include mq-max(sm){
            width: 75px;
            height: 75px;
          }
      }

      &.plus{
        width: 2em;
        text-align: center;
        @include mq-max(md){
          width: .5em;
        }
      }
    }
    // ./--- xSellProduct
    .buy_this_with{
      padding-left: 1em;
      @include mq-max(md){
        padding-left: .5em;
      }
    }
}

// ページ送り
.page_navi{
  padding: 20px 0 40px;
  border-top: 1px solid #bfbfbf;
  text-align: center;
  @include clearfix;
  ul{
    // background-color: $textColor;
    display: inline-block;
    border: 1px solid $borderColor;
  }
.current,li a{
  // width: 35px;
}
  li{
    padding: 5px 0;

    width: 35px;
    // height: 100%;
    height: 34px;
    // background-color: #fff;
    float: left;
    display: inline-block;
    vertical-align: middle;
    // display: block;
    // padding: 0 5px;
    &:not(:last-child){
      border-right: 1px solid $borderColor;
    }
    a,span{
      // padding: 5px 0;
      font-size: 16px;
      display: block;
      height: 100%;
    }
  }
  dl{
    font-size: .8em;
  }
  dt,dd{
    display: inline-block;
  }
}


// #lower{
//   #searchCategories{
//     strong{
//       color: #1e7fc3;
//     }
//   }
// }
