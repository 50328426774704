//変数定義
$btnSP: 240px;
$borderColor: #cbcbcb;
$btnblue: #0c6fb4;

//画像パス指定(Live Commercedemo機用なので注意 http://mt-agi.japan-onlinestores.com/8)
$path : "../../../../../../../resources/c_media/themes/theme_0";


/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/

// 全体のコンテンツ幅
$contentWidth: 980px;
$contentPadding: 10px;//パディング

/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/

//全体をレスポンシブ非対応にする時は false
$responsiveMode : true;


// max-width
$breakpoint-max: (
  'sm': 'screen and (max-width: 600px)', //スマホ境界
  'md': 'screen and (max-width: 800px)', //タブレット境界
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (max-width: 1200px)',
) !default;


/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/

$keyColor: #000; //キーカラー
$accentColor: #cc0000; //アクセントカラー

/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/

$textColor: #666;
$textColorLight: lighten($textColor,10);
$textColorDark: darken($textColor,10);

//Border
$bdr_default : 1px solid;
$bdr_gray    : #D0D0D0;

$lineHeight: 1.3em; // 基本的な行間

// サンセリフ・ゴシック体
$sanserif: 游ゴシック体, YuGothic-M, "Yu Gothic", YuGothic, "ヒラギノ角ゴシック Pro", "Hiragino Kaku Gothic Pro", メイリオ, Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
// セリフ・明朝体
$serif: "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
