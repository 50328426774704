// opensansの使用
$path : "//mt-agi-c.japan-onlinestores.com/resources/c_media/themes/theme_0";
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 200;
  src: url( $path + '/font/opensans/OpenSans-Light.woff2') format('woff2');
  src: url( $path + '/font/opensans/OpenSans-Light.ttf') format('truetype'), url( $path + '/font/opensans/OpenSans-Light.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url( $path + '/font/opensans/OpenSans-Regular.woff2') format('woff2');
  src: url( $path + '/font/opensans/OpenSans-Regular.ttf') format('truetype'), url( $path + '/font/opensans/OpenSans-Regular.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 500;
  src: url( $path + '/font/opensans/OpenSans-SemiBold.woff2') format('woff2');
  src: url( $path + '/font/opensans/OpenSans-SemiBold.ttf') format('truetype'), url( $path + '/font/opensans/OpenSans-SemiBold.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url( $path + '/font/opensans/OpenSans-Bold.woff2') format('woff2');
  src: url( $path + '/font/opensans/OpenSans-Bold.ttf') format('truetype'), url( $path + '/font/opensans/OpenSans-Bold.woff') format('woff');
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 900;
  src: url( $path + '/font/opensans/OpenSans-ExtraBold.woff2') format('woff2');
  src: url( $path + '/font/opensans/OpenSans-ExtraBold.ttf') format('truetype'), url( $path + '/font/opensans/OpenSans-ExtraBold.woff') format('woff');
}
