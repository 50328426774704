// サイト全体 共通SCSS

//ユーティリティ群
@import "util/all";

//ベース読込
@import "base/ress"; // 初期化CSS
@import "base/base"; // 共通部分
// @import "base/layout"; // レイアウト
@import "base/lower"; // 下層共通CSS
@import "base/gridlex/gridlex"; //グリッド用
@import "base/fontawesome/font-awesome"; //アイコンフォント

//Noto sans用
@import "util/notosans";

//Open sans用
@import "util/opensans";

//module群
@import "module/all";

//レスポンシブ(LiveCommerceデフォルトテーマ修正用)
@import "module/responsive";



body{
  font-family: 'Open Sans','Noto Sans Japanese',sans-serif;
}
