//全体共通で使うCSS定義
body{
  word-wrap: break-word;
  box-sizing: border-box;
}

a{
  text-decoration: none;
  color: $textColor;
}
p{
  font-size: 16px;
  color: $textColor;
  margin: 30px 0;
  line-height: 2em;
}
h2 {
  margin: 10px 0;
  color: $textColor;
  font-weight: lighter;
  font-size: 3em;
  @include mq-max(sm) {
    font-size: 40px;
  }
}
h3{
  margin: 10px 0;
  font-size: 1.5em;
  font-weight: normal;
  @include mq-max(sm) {
    font-size: 22px;
  }

}
h4{
  font-size: 1.1em;
  font-weight: normal;
  margin: 10px 0;
}
li {
  list-style: none;
}

#header,
.item_detail{

  label{
    margin-left: 5px;
    position: relative;
    display: inline-block;
    background: #fff;
        @include mq-max(md){
            background: transparent;
        }
    &:before{
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      background: #dcdcdc;
      height: 30px;
      width: 30px;
    }

    &:after{
      content: '';
      position: absolute;
      top: 12px;
      //bottom: 0;
      margin: auto 0;
      right: 11px;
      width: 0;
      //height: 0;
      border-style: solid;
      border-width: 6px 4px 0 4px;
      border-color: #666 transparent transparent transparent;
    }
  }
}

select{
  width: 170px;
  padding: 0;
  margin: 0;
  height: 30px;
  //background: #fff;
  position: relative;
  padding: 0 40px 0 10px;
  border: 1px solid #ccc;
  cursor: pointer;
    @include mq-max(md){
        background: #fff;
    }


  &:-ms-expand {
    display: none;
  }
}
